.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-evenly;
  width: 1527px;
  max-width: 1527px;
}

.main {
  width: 300px;
  height: 480px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 1px 10px 1px black;
  overflow: hidden;
  margin: 30px;
}

.upperContainer {
  height: 100px;
  /* background: linear-gradient(to left,  )  */
  background-color: #726cf8;
  background-image: linear-gradient(315deg, #726cf8 0%, #e975a8 74%);
}

.imageContainer img {
  background: white;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 5px;
  transform: translate(95px, 45px);
  border: 2px solid #8e00b9;
  border-radius: 50%;
}

/* img {
} */

.lowerContainer {
  margin-top: 60px;
  height: 300px;
  background: white;
  text-align: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial,
    sans-serif;
}

.lowerContainer h3,
h4,
p {
  margin: 0;
  padding: 0;
}

.lowerContainer h3 {
  color: #2f2d2e;
  font-weight: 600;
  font-size: 1.5rem;
}

.lowerContainer h4 {
  color: #8e00b9;
  font-weight: 700;
  font-size: 1.2rem;
}

.lowerContainer p {
  margin: 1rem;
  font-family: sans-serif;
  color: #2f2d2e;
  font-weight: 300;
  font-size: 0.9rem;
  text-align: justify;
}

button {
  margin: 1rem;
  margin-top: 0;
  color: #2f2d2e;
  font: bold;
  padding: 0.6rem;
  background-color: white;
  border: 2px solid #8e00b9;
  border-radius: 6px;
  transition-duration: 0.5s;
  animation: ease-in-out;
}

button:hover {
  background-image: linear-gradient(315deg, #726cf8 0%, #e975a8 74%);
  transform: scale(1.2);
  border: none;
  color: white;
}
