.navbar {
  background-color: #045de9;
  background-image: linear-gradient(315deg, #045de9 0%, #09c6f9 74%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: montserrat, sans-serif;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbarContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
  color: #fff;
}

.navbarLogo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.faFish {
  /* margin-left: 0.5rem;
  font-size: 1.8rem; */
  display: none;
}

.navMenu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.navItem {
  height: 80px;
}

.navLinks {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.navLinks:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.faBars {
  color: #fff;
}

.navLinksMobile {
  display: none;
}

.menuIcon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .navMenu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .active {
    background-color: black;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .navLinks {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .navLinks:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbarLogo {
    position: absolute;
    top: 10px;
    left: 0;
    transform: translate(25%, 50%);
    font-size: 18px;
  }

  .menuIcon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .faTimes {
    color: #fff;
    font-size: 2rem;
  }

  .navLinksMobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .navLinksMobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}

/* .navBar {
 

  background-color: #045de9;
  background-image: linear-gradient(315deg, #045de9 0%, #09c6f9 74%);
  color: white;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}

.navItems {
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;
}

.navItems ul {
  display: flex;
  align-items: center;
  flex-grow: 1;
  text-align: center;
  justify-content: space-evenly;
}

.navItems li {
  text-decoration: none;
  list-style: none;
  text-align: center;
  font-family: montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  width: 100%;
}

.title {
  display: flex;
  align-items: center;
  margin: 10px;

  font-family: montserrat;
  font-weight: 700;
  font-size: 24px;
} */

/* .logoContainer {
    margin-right: 40px;
  } */

/* .logo {
    width: 100%;
  } */

/* .navBtn {
    color: #151515;
    background-color: #ef6848;
    border-radius: 31px;
    padding: 10px 55px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
  } */
