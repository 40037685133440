.mainFullProfile {
  display: flex;
  flex-direction: column;
}

.mainFullProfile h1 {
  margin-top: 20px;
  margin-bottom: 10px;

  text-align: center;
}

.mainFullProfile h3 {
  margin-bottom: 20px;
  text-align: center;
}

.mainFullProfile h4 {
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: left;
}

.mainFullProfile p {
  text-align: left;
  margin-bottom: 20px;
}
